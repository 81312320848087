<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 pb-12">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="12" sm="7" class="mt-3 ml-2">
                    <h1 class="service_title">Non hai un sito WEB?</h1>
                    <div class="small_screen_service_image">
                      <v-img
                        v-if="$vuetify.breakpoint.smAndDown"
                        :src="require('@/assets/imgs/pages/sviluppo_web.png')"
                        alt="Icona assistenza"
                        class="mt-2 mb-6"
                        contain
                        max-width="200"
                        :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                      ></v-img>
                    </div>
                    <h2 class="service_subtitle">Ci pensiamo noi!</h2>

                    <h3>Che cos'è un sito WEB?</h3>
                    <p>
                      Un sito web è un'importante strumento di comunicazione che
                      viene utilizzato da aziende o da persone che vogliano
                      illustrare e descrivere esperienze, prodotti e servizi
                      attraverso l'utilizzo di pagine contenenti immagini, testi
                      e contenuti multimediali.
                    </p>

                    <h3>Come funziona?</h3>
                    <p>
                      Le pagine che compongono i siti WEB sono tra loro
                      collegate attraverso dei link, collegamenti che consentono
                      all'utilizzatore di spostarsi con facilità tra una sezione
                      e un'altra.
                      <br />
                      I siti WEB devono essere pubblicati su server online,
                      servizi di hosting che rendono accessibili i contenuti a
                      tutti coloro che ne fanno richiesta attraverso i propri
                      dispositivi.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                    <v-img
                      class="service_image_position"
                        :src="require('@/assets/imgs/pages/sviluppo_web.png')"
                      alt="Icona assistenza"
                      contain
                      style=""
                      max-width="200"
                      :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                    ></v-img>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="7" offset-sm="4">
                    <h3>Perchè è importante avere un sito Web?</h3>
                    <p>
                      Un sito Web ti consente di portare online il tuo marchio e
                      decidere come e cosa comunicare ai tuoi visitatori.
                      <br />
                      I siti sono inoltre flessibili e possono essere adattati
                      per accogliere i vostri nuovi prodotti, servizi o eventi.
                    </p>
                    <p>
                      Secondo le stime del DigitalReport 2021, ogni giorno, nel
                      mondo 4,66 miliardi di persone utilizzano internet e in
                      Italia sono 50,54 milioni gli utenti attivi giornalmente.
                    </p>
                    <p>
                      Essere presente online con il tuo sito Web ti permette
                      quindi di consolidare la tua presenza e raggiungere un
                      bacino di utenza interessata ai tuoi prodotti o servizi
                      dai numeri potenzialmente considerevoli.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <h3>Quali tipi di siti WEB esistono?</h3>
                    <p>
                      I siti Web possono essere di molteplici tipologie ma i più
                      comuni sono:
                    </p>
                    <ul>
                      <li><h4>Siti web di e-commerce</h4></li>
                      <p>
                        Consentono ai visitatori di consultare un catalogo di
                        prodotti, scegliere e aggiungere oggetti ad un carrello
                        virtuale, procedere al pagamento online e fornire le
                        informazione per la spedizione.
                      </p>

                      <li><h4>Siti web portfolio</h4></li>
                      <p>
                        Sono un biglietto da visita per figure creative come
                        fotografi, videomaker, artisti e designer che intendono
                        mostrare i propri lavori ed attirare nuovi clienti.
                      </p>
                      <li>
                        <h4>Siti web aziendali</h4>
                      </li>
                      <p>
                        Simili ai siti web portfolio, hanno l'obiettivo di
                        promuovere i propri prodotti e servizi offendo
                        informazioni tecniche, manuali e casi d'uso.
                      </p>
                      <li><h4>Blog</h4></li>
                      <p>
                        Sono diari online che permettono di esprimere pensieri o
                        dove condividere le proprie esperienze, attività,
                        recensioni.
                      </p>
                      <li><h4>Landing pages</h4></li>
                      <p>
                        Le landing pages, pagine di atterraggio, sono pagine
                        semplici, normalmente 'slegate' dal sito principale e
                        vengono principalmente utilizzate nelle campagne di
                        marketing per raccogliere dati di contatto.
                      </p>
                    </ul>

                    <p>
                      I siti Web a seconda della loro tipologia possono quindi
                      essere racchiusi in due macro categorie:
                    </p>

                    <ul>
                      <li>Siti statici</li>
                      <li>Siti dinamici</li>
                    </ul>
                    <div class="mt-2">
                      <v-icon>mdi-hand-pointing-right</v-icon>
                      <router-link :to="{ name: 'InDeep_WebsiteTypes' }">
                        Scopri di più sui siti web statici e dinamici
                      </router-link>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="7" offset-sm="4">
                    <h3>
                      Quali caratteristiche deve avere un sito Web che funziona?
                    </h3>
                    <ul>
                      <li>
                        <h4>Essere accattivante, descrittivo e di qualità</h4>
                      </li>
                      <p>
                        I tuoi visitatori devono essere coinvolti in una
                        esperienza di navigazione semplice e intuitiva e che
                        allo stesso tempo rispetti l'immagine e i toni per
                        creare un forte legame con la tua azienda o persona.
                        <br />
                        I contenuti delle tue pagine devono essere esaustivi,
                        rispondere alle aspettative dei tuoi visitatori,
                        rispettare una struttura di titoli e paragrafi ed
                        esaltare i messaggi che vuoi trasmettere.
                      </p>

                      <li>
                        <h4>Essere veloce</h4>
                      </li>
                      <p>
                        Secondo alcune analisi la maggior parte degli utenti
                        abbandonerà il tuo sito se il tempo di cariamento supera
                        i 3 secondi.
                        <br />
                        Per questo è fondamentale che l'insieme di struttura del
                        sito, contenuti multimediali e piattaforma di hosting
                        siano ottimizzati per ridurre al minimo i tempi di
                        accesso.
                      </p>

                      <li>
                        <h4>Responsive per adattarsi ai dispositivi</h4>
                        <p>
                          Le visite al tuo sito possono arrivare da grande
                          varietà di dispositivi da PC, tablet, smartphone e
                          ognuno possiede dimensioni e risoluzioni dello schermo
                          differenti.
                          <br />
                          Il tuo sito deve essere in grado di adattare
                          automaticamnente il layout dei contenuti per offrire
                          sempre una buona leggibilità e navigabilità agli
                          utenti.
                        </p>
                      </li>

                      <li>
                        <h4>Apparire nei risultati di ricerca: SEO & SERP</h4>
                        <p>
                          Un sito web deve essere indicizzabile dai motori di
                          ricerca come Google o Bing per generare traffico di
                          tipo organico.
                          <br />
                          Per traffico organico si intendono visite al sito web
                          generate da un buon posizionamento nei motori di
                          ricerca.
                          <br />
                          Agendo sui contenuti e la loro struttura, utilizzando
                          parole chiave, titoli e descrizioni sulle pagine si va
                          a ottimizzare la SEO, la Search Engine Optimization e
                          migliorare la SERP ovvero il reale posizionamento
                          all'interno dei risultati di ricerca.
                        </p>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="12" sm=6 offset-sm=3>
                    <!-- Servizi offerti -->
                    <div class="mt-6 mb-12">
                      <h3 class="mb-2">
                        <strong>Di cosa ci occupiamo?: </strong>
                      </h3>
                      <ul>
                        <li>Valutazione delle esigenze</li>
                        <li>Design, UX e pagine responsive</li>
                        <li>Progettazione, sviluppo e deploy</li>
                        <li>Realizzazione di materiale fotografico</li>
                        <li>Registrazione e gestione domini</li>
                        <li>Amministrazione dei server di hosting</li>
                      </ul>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <contactCardButton
                      contact_type="web"
                      title="Sei interessato allo sviluppo o alla gestione di un sito Web ?"
                    />
                  </v-col>

                  <!-- <v-col cols="12" class="text-center">
                    <v-card
                      small
                      text
                      color="white black--text"
                      :to="{ name: 'OurServicesSviluppo' }"
                      flat
                    >
                      <v-card-actions>
                        <v-spacer />
                        Torna all'elenco dei servizi
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Sviluppo Web",
    meta: [
      {
        name: "description",
        content:
          "Creazione di siti web statici, dinamici, blog ed ecommerce. Vogliamo portare la tua azienda online nel migliore dei modi ",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>